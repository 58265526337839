import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "2006 - Present",
    degree: "Full-stack Web Developer and Designer",
    content:
      "Working as a Full-stack Web developer and designer for more than 17 years ",
  },
  {
    id: 2,
    year: "2004 - 2006",
    degree: "Bachelor’s Degree",
    content:
      "Bachelor’s Degree in Software Engineering",
  },
  {
    id: 3,
    year: "2002 - 2004",
    degree: "Associate Degree",
    content:
      "Associate Degree in Software Engineering",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
