import React, {useEffect} from "react";
// import { Link } from "react-router-dom";
import Layout2 from "../components/Layout/Layout2";
import NotFound2 from "../components/Sections/NotFound2";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout2>
      <NotFound2 />
    </Layout2>
  );
}

export default NotFound;
