import React, { useState, useEffect } from "react";
import Portfolio2 from "../Items/Portfolio2";

const filters = [
  {
    id: 1,
    name: "All Projects",
  },
  {
    id: 2,
    name: "Logo Design",
  },
  {
    id: 3,
    name: "UI/UX",
  },
  {
    id: 4,
    name: "Backend",
  },
  {
    id: 5,
    name: "Frontend",
  },
  {
    id: 6,
    name: "Graphic Design",
  }
];

const allData = [
  {
    id: 1,
    name: "Pelak52",
    category: ["backend", "frontend", "ui/ux"],
    image: "images/portfolio/thumbs/pelak52.jpg",
    slug: "pelak52",
  },
  {
    id: 2,
    name: "Ketabet",
    category: ["logo design", "backend", "frontend", "ui/ux"],
    image: "images/portfolio/thumbs/ketabet.jpg",
    slug: "ketabet",
  },
  {
    id: 3,
    name: "HunMedia",
    category: ["logo design"],
    image: "images/portfolio/thumbs/hunmedia.jpg",
    slug: "hunmedia-studio",
  },
  {
    id: 4,
    name: "Hirbod Academy",
    category: ["ui/ux", "backend", "frontend", "logo design"],
    image: "images/portfolio/thumbs/hirbod.jpg",
    slug: "hirbod-academy",
  },
  {
    id: 5,
    name: "UTEE App",
    category: ["ui/ux", "frontend", "backend", "dashboard", "webservice"],
    image: "images/portfolio/thumbs/utee.jpg",
    slug: "utee-app",
  },
  {
    id: 6,
    name: "30Book",
    category: ["logo design", "backend", "frontend", "dashboard", "webservice"],
    image: "images/portfolio/thumbs/30book.jpg",
    slug: "30book",
  },
  {
    id: 7,
    name: "SixDaysEnd",
    category: ["logo design", "backend", "frontend"],
    image: "images/portfolio/thumbs/sixdaysend.jpg",
    slug: "sixdaysend",
  },
  {
    id: 8,
    name: "Hensel",
    category: ["logo design", "graphic design"],
    image: "images/portfolio/thumbs/hensel.jpg",
    slug: "hensel",
  },
  {
    id: 9,
    name: "Perarin",
    category: ["logo design", "backend", "graphic design"],
    image: "images/portfolio/thumbs/perarin.jpg",
    slug: "perarin",
  },
  {
    id: 10,
    name: "Matinall",
    category: ["logo design", "graphic design"],
    image: "images/portfolio/thumbs/matinall.jpg",
    slug: "matinall",
  },
  {
    id: 11,
    name: "AzadehBarbad",
    category: ["logo design", "graphic design"],
    image: "images/portfolio/thumbs/azadehbarbad.jpg",
    slug: "azadeh-barbad",
  },
  {
    id: 12,
    name: "Eurpean Commision Project",
    category: ["frontend"],
    image: "images/portfolio/thumbs/ecproject.jpg",
    slug: "ecproject",
  },
  {
    id: 13,
    name: "Cenesta",
    category: ["graphic design", "backend", "frontend"],
    image: "images/portfolio/thumbs/cenesta.jpg",
    slug: "cenesta",
  },
  {
    id: 14,
    name: "Hende3",
    category: ["logo design"],
    image: "images/portfolio/thumbs/hende3.jpg",
    slug: "hende3",
  },
  {
    id: 15,
    name: "Zedix",
    category: ["frontend", "backend", "graphic design"],
    image: "images/portfolio/thumbs/zedix.jpg",
    slug: "zedix",
  },
  {
    id: 16,
    name: "Maluan",
    category: ["logo design"],
    image: "images/portfolio/thumbs/maluan.jpg",
    slug: "maluan",
  },
  {
    id: 17,
    name: "ICCA Consortium",
    category: ["backend"],
    image: "images/portfolio/thumbs/icca.jpg",
    slug: "icca-consortium",
  },
  {
    id: 18,
    name: "Computerchi",
    category: ["backend"],
    image: "images/portfolio/thumbs/computerchi.jpg",
    slug: "computerchi",
  },
  {
    id: 19,
    name: "Ketab Love",
    category: ["frontend", "backend"],
    image: "images/portfolio/thumbs/ketablove.jpg",
    slug: "ketab-love",
  },
  {
    id: 20,
    name: "Aspa Design",
    category: ["logo design", "frontend"],
    image: "images/portfolio/thumbs/aspa.jpg",
    slug: "aspa-design",
  },
  {
    id: 21,
    name: "Architects Paper",
    category: ["graphic design"],
    image: "images/portfolio/thumbs/architects.jpg",
    slug: "architects-paper",
  },
  {
    id: 22,
    name: "Sitepack",
    category: ["logo design"],
    image: "images/portfolio/thumbs/sitepack.jpg",
    slug: "sitepack",
  },
  {
    id: 23,
    name: "Seven Studio",
    category: ["logo design"],
    image: "images/portfolio/thumbs/seven.jpg",
    slug: "seven",
  },
  {
    id: 24,
    name: "Melorina Stone",
    category: ["backend"],
    image: "images/portfolio/thumbs/melorina.jpg",
    slug: "melorina-stone",
  },
];

function Portfolios2() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(6);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter === filters[0].name.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter((data) => {
        console.log(data.category)
        return (
          data.category.includes(targetFilter) && data.id <= dataVisibleCount
        );
      });
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;

    if (tempCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        let items = getAllItems.filter((data) => {
          return data.category.includes(activeFilter) && data.id <= tempCount;
        });
        setVisibleItems(items);
      }
    }
  };

  return (
    <>
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={handleChange}
          >
            {filter.name}
          </li>
        ))}
      </ul>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
            <Portfolio2 portfolio={item} />
          </div>
        ))}
      </div>

      {noMorePost ? null : (
        <div className="load-more text-center mt-4">
          <a
            href="#!"
            className="btn btn-default"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Load more
          </a>
        </div>
      )}
    </>
  );
}

export default Portfolios2;
