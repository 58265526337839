import React from "react";
// import { Link as ScrollLink } from "react-scroll";



function NotFound2() {
  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4 text-center">
          404
          <span className="dot"></span>
        </h1>
        <p className="mb-4 text-center">The Page Not Found.</p>
        <a
          href="/"
          className="btn btn-default btn-lg mr-0 text-center"
        >
          <i className="icon-home"></i>Back to Home
        </a>
        <div
          className="spacer d-md-none d-lg-none d-sm-none"
          data-height="10"
        ></div>
        
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default NotFound2;
