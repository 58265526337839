import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";

const skillData = {
  skillContent:
    "Below, you can find out which technologies I experienced. Skilled in a diverse array of cutting-edge web design and development technologies, I offer a depth of expertise to your project. ",
  progressData: [
    {
      id: 1,
      name: "JavaScript",
      percentage: 90,
    },
    {
      id: 2,
      name: "HTML & CSS",
      percentage: 90,
    },
    {
      id: 3,
      name: "TypeScript",
      percentage: 85,
    },
    {
      id: 4,
      name: "WordPress",
      percentage: 80,
    },
    {
      id: 5,
      name: "PHP",
      percentage: 80,
    },
    {
      id: 6,
      name: "React",
      percentage: 85,
    },
    {
      id: 7,
      name: "Next.JS",
      percentage: 80,
    },
    {
      id: 8,
      name: "Gatsby",
      percentage: 80,
    },
    {
      id: 9,
      name: "Photoshop",
      percentage: 95,
    },
    {
      id: 10,
      name: "Figma",
      percentage: 85,
    },
  ],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
