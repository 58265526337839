import React from "react";
import { Link as ScrollLink } from "react-scroll";

const herosectionData = {
  name: "Vahid Asefi",
  aboutMe:
    "A full-stack web developer and designer, I'm a skilled full-stack web developer and designer, crafting modern, user-friendly websites with expertise in both front-end and back-end development. ",
};

function Herosection() {
  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4">
          I’m {herosectionData.name}
          <span className="dot"></span>
        </h1>
        <p className="mb-4">{herosectionData.aboutMe}</p>
        <a
          href="/works"
          className="btn btn-default btn-lg mr-3"
        >
          <i className="icon-grid"></i>View Portfolio
        </a>
        <div
          className="spacer d-md-none d-lg-none d-sm-none"
          data-height="10"
        ></div>
        <a
          href="/contact"
          className="btn btn-border-light btn-lg"
        >
          <i className="icon-envelope"></i>Hire me
        </a>
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Herosection;
