import React, { useState, useEffect } from "react";
// import { Redirect, BrowserRouter} from "react-router-dom";
import Markdown from "markdown-to-jsx";
import fm from "front-matter";
import Layout2 from "../components/Layout/Layout2";
// import Herosection from "../components/Sections/NotFound";
import NotFound from "../components/Sections/NotFound";




function WorkDetails2(props) {
  const [meta, setMeta] = useState("");
  const [content, setContent] = useState("");
  const title = props.match.params.title;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    import(`../works/${title}.md`)
      .then((res) => res.default)
      .then((res) => {
        fetch(res)
          .then((data) => data.text())
          .then((res) => {
            let result = fm(res);
            setMeta(result.attributes);
            setContent(result.body);
          });
      })
      .catch((err) => {
        setMeta(null)
        setContent(null)
      });
  }, [title]);
  
    if(meta){
      return (
        <Layout2>
          <section className="shadow-blue white-bg padding mt-0">
            <ul className="list-inline portfolio-info mt-0">
              
              <li className="list-inline-item">
                <i className="icon-calendar"></i>
                {meta.date}
              </li>
              <li className="list-inline-item">
                <a
                  href={meta && meta.link}
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-link"></i> {meta && meta.link}
                </a>
              </li>
              <li className="list-inline-item">
                <i className="icon-tag"></i>
                {meta.tags}
              </li>
            </ul>
            <Markdown className="work-wrapper" children={content}></Markdown>
          </section>
        </Layout2>
      );
    }
    if(meta === null){
      return(
        
          <Layout2>
            <NotFound />
          </Layout2>
        );
      
    }
    return(
      <Layout2>
        <section>
          
        </section>
      </Layout2>
    )
     
  
 
}

export default WorkDetails2;
