import React from "react";
import TrackVisibility from "react-on-screen";
// import Skill from "../Items/Skill";



function Extra() {
  return (
    <>
      <p className="mb-0">In addition to my tech skills, I'm all about the team spirit. I'm well-versed in Agile development, making me a pro at working closely with others, adapting to changes, and delivering top-notch results. I'm a big fan of Git – it's like my secret weapon for smooth project management and collaboration. My attention to detail and my commitment to excellence are what make me not just a developer, but someone who creates online experiences that stand out. </p>
      
    </>
  );
}

export default Extra;
